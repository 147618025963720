import React from "react";
import PersistentDrawerLeft from "../../components/userComp/headerNav/HeaderNav";
import HorizontalNonLinearStepper from "../../components/userComp/Stepper";
import styles from "./Introduction.module.css";

const Introduction = () => {
  return (
    <PersistentDrawerLeft>
      <div className={styles.int}>
        <div className={styles.head}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8559 15.7133C17.5377 15.7148 17.224 15.788 16.9381 15.9276L13.8669 12.8563H11.4277V14.2848H13.2748L15.9282 16.9382C15.7885 17.2241 15.715 17.5378 15.7132 17.856C15.7132 18.2798 15.8388 18.6941 16.0743 19.0465C16.3097 19.3988 16.6444 19.6735 17.0359 19.8356C17.4275 19.9978 17.8583 20.0403 18.2739 19.9576C18.6896 19.8749 19.0714 19.6708 19.371 19.3712C19.6707 19.0715 19.8748 18.6897 19.9575 18.2741C20.0401 17.8584 19.9977 17.4276 19.8355 17.036C19.6733 16.6445 19.3987 16.3099 19.0463 16.0744C18.694 15.839 18.2797 15.7133 17.8559 15.7133ZM17.8559 18.5703C17.7146 18.5703 17.5765 18.5284 17.4591 18.4499C17.3416 18.3714 17.2501 18.2599 17.196 18.1294C17.142 17.9988 17.1278 17.8552 17.1554 17.7167C17.1829 17.5781 17.251 17.4509 17.3509 17.351C17.4507 17.2511 17.578 17.1831 17.7166 17.1555C17.8551 17.128 17.9987 17.1421 18.1292 17.1962C18.2597 17.2502 18.3713 17.3418 18.4498 17.4592C18.5283 17.5767 18.5701 17.7148 18.5701 17.856C18.5701 18.0455 18.4949 18.2271 18.3609 18.3611C18.227 18.495 18.0453 18.5703 17.8559 18.5703Z"
              fill="#005792"
            />
            <path
              d="M17.8559 7.85666C17.4142 7.85797 16.9837 7.99599 16.6236 8.25176C16.2635 8.50754 15.9913 8.86852 15.8446 9.28514H11.4277V10.7136H15.8446C15.9762 11.083 16.2068 11.4091 16.5111 11.6563C16.8155 11.9035 17.182 12.0623 17.5705 12.1153C17.9591 12.1683 18.3547 12.1135 18.7142 11.9569C19.0736 11.8003 19.3832 11.5479 19.6089 11.2272C19.8346 10.9066 19.9679 10.5301 19.9941 10.1388C20.0203 9.74759 19.9385 9.35666 19.7575 9.00878C19.5766 8.66089 19.3035 8.36943 18.9682 8.16624C18.6328 7.96306 18.248 7.85596 17.8559 7.85666ZM17.8559 10.7136C17.7146 10.7136 17.5765 10.6717 17.4591 10.5933C17.3416 10.5148 17.2501 10.4032 17.196 10.2727C17.142 10.1422 17.1278 9.99859 17.1554 9.86004C17.1829 9.7215 17.251 9.59423 17.3509 9.49434C17.4507 9.39445 17.578 9.32643 17.7166 9.29887C17.8551 9.27131 17.9987 9.28545 18.1292 9.33951C18.2597 9.39357 18.3713 9.48512 18.4498 9.60257C18.5283 9.72003 18.5701 9.85812 18.5701 9.99939C18.5701 10.1888 18.4949 10.3705 18.3609 10.5044C18.227 10.6384 18.0453 10.7136 17.8559 10.7136Z"
              fill="#005792"
            />
            <path
              d="M17.8559 8.98362e-07C17.2878 0.000568159 16.7431 0.226501 16.3414 0.628217C15.9397 1.02993 15.7137 1.57461 15.7132 2.14272C15.7156 2.48488 15.8009 2.82136 15.9617 3.12338L13.2819 5.71393H11.4277V7.14241H13.859L17.0017 4.10546C17.2936 4.23276 17.6102 4.29336 17.9286 4.28285C18.2469 4.27234 18.5588 4.19098 18.8417 4.0447C19.1246 3.89842 19.3713 3.69089 19.5639 3.43723C19.7565 3.18357 19.8901 2.89016 19.955 2.57836C20.0198 2.26655 20.0144 1.94421 19.939 1.63479C19.8635 1.32536 19.7201 1.03665 19.519 0.789667C19.3179 0.542684 19.0643 0.343646 18.7766 0.207043C18.4889 0.0704395 18.1744 -0.000290764 17.8559 8.98362e-07ZM17.8559 2.85697C17.7146 2.85697 17.5765 2.81508 17.4591 2.73659C17.3416 2.65811 17.2501 2.54656 17.196 2.41605C17.142 2.28554 17.1278 2.14193 17.1554 2.00338C17.1829 1.86483 17.251 1.73757 17.3509 1.63768C17.4507 1.53779 17.578 1.46977 17.7166 1.44221C17.8551 1.41465 17.9987 1.42879 18.1292 1.48285C18.2597 1.53691 18.3713 1.62846 18.4498 1.74591C18.5283 1.86337 18.5701 2.00146 18.5701 2.14272C18.5701 2.33215 18.4949 2.51382 18.3609 2.64777C18.227 2.78172 18.0453 2.85697 17.8559 2.85697Z"
              fill="#005792"
            />
            <path
              d="M11.4279 2.85696H12.8563V1.42848H11.4279C11.0208 1.42984 10.6188 1.51868 10.2491 1.68899C9.87945 1.8593 9.55069 2.10708 9.28514 2.41556C9.01958 2.10708 8.69082 1.8593 8.32112 1.68899C7.95143 1.51868 7.54944 1.42984 7.14241 1.42848H6.42817C4.72389 1.43037 3.08996 2.10823 1.88486 3.31334C0.679749 4.51844 0.00189049 6.15238 0 7.85665V12.1421C0.00189049 13.8464 0.679749 15.4803 1.88486 16.6854C3.08996 17.8905 4.72389 18.5684 6.42817 18.5703H7.14241C7.54944 18.5689 7.95143 18.4801 8.32112 18.3098C8.69082 18.1395 9.01958 17.8917 9.28514 17.5832C9.55069 17.8917 9.87945 18.1395 10.2491 18.3098C10.6188 18.4801 11.0208 18.5689 11.4279 18.5703H12.8563V17.1418H11.4279C11.0491 17.1414 10.686 16.9908 10.4182 16.723C10.1504 16.4552 9.99975 16.092 9.99938 15.7133V4.28545C9.99975 3.90671 10.1504 3.54359 10.4182 3.27578C10.686 3.00796 11.0491 2.85734 11.4279 2.85696ZM7.14241 17.1418H6.42817C5.22713 17.1396 4.06694 16.7055 3.1595 15.9187C2.25206 15.1319 1.65793 14.045 1.48562 12.8563H2.85696V11.4279H1.42848V8.57089H3.57121C4.13932 8.57033 4.684 8.34439 5.08571 7.94268C5.48743 7.54096 5.71336 6.99628 5.71393 6.42817V4.99969H4.28545V6.42817C4.28545 6.6176 4.2102 6.79927 4.07625 6.93322C3.9423 7.06716 3.76063 7.14241 3.57121 7.14241H1.48562C1.65793 5.9538 2.25206 4.86683 3.1595 4.08003C4.06694 3.29323 5.22713 2.85911 6.42817 2.85696H7.14241C7.52115 2.85734 7.88427 3.00796 8.15208 3.27578C8.41989 3.54359 8.57052 3.90671 8.57089 4.28545V7.14241H7.14241V8.57089H8.57089V11.4279H7.14241C6.5743 11.4284 6.02962 11.6544 5.6279 12.0561C5.22619 12.4578 5.00026 13.0025 4.99969 13.5706V14.9991H6.42817V13.5706C6.42817 13.3812 6.50342 13.1995 6.63737 13.0655C6.77131 12.9316 6.95298 12.8563 7.14241 12.8563H8.57089V15.7133C8.57052 16.092 8.41989 16.4552 8.15208 16.723C7.88427 16.9908 7.52115 17.1414 7.14241 17.1418Z"
              fill="#005792"
            />
          </svg>

          <h1>Introduction</h1>
        </div>
        <p className={styles.p}>
          Master ADCLOUD and basics of ad creation & optimization in 4 steps.
        </p>
        <HorizontalNonLinearStepper />
      </div>
    </PersistentDrawerLeft>
  );
};

export default Introduction;
